import { useEffect, useState } from "react"
import { AiFillStar } from "react-icons/ai"
import { BiLeftArrowAlt } from "react-icons/bi"
import { FaUserCircle } from "react-icons/fa"
import { Link } from "react-router-dom"
import { Course } from "src/types"
import { visitWhatsapp } from "../Shared/Whatsapp"
import courses from "./../../data/courses.json"
import { classNames } from "src/helpers/mixins"
import IconMod1 from "src/assets/images/courses/modulo1.jpeg"
import IconMod2 from "src/assets/images/courses/modulo2.jpeg"
import IconMod3 from "src/assets/images/courses/modulo3.jpeg"
import IconMod4 from "src/assets/images/courses/modulo4.jpeg"
import IconMod5 from "src/assets/images/courses/modulo5.jpeg"
import IconMod6 from "src/assets/images/courses/modulo6.jpeg"
type TParams = {
  id: number | string
  type: string
}
const AcademyDetail: React.FC<TParams> = ({ id, type }) => {
  const [course, setCourse] = useState<Course>()
  const [tab, setTab] = useState<string>("description")
  useEffect(() => {
    setCourse(courses.find((_, index) => index.toString() === id?.toString()))

  }, [id])
  const getImage = (position:string) => {
    switch(position) {
      case 'Módulo 1':
      return IconMod1
      case 'Módulo 2':
      return IconMod2
      case 'Módulo 3':
      return IconMod3
      case 'Módulo 4':
      return IconMod4
      case 'Módulo 5':
      return IconMod5
      case 'Módulo 6':
      return IconMod6                              

    }
  }
  return (
    <section className="w-full">
      
      <Link to={ (type === 'home') ? '/academia' : '/aprender'}>
        <div className="flex items-center gap-1 text-green-admin-light font-medium">
          <BiLeftArrowAlt size={26} />  Volver a la página anterior
        </div>
      </Link>
      <div className="mt-10 flex text-center font-medium text-2xl ">
        {course?.position} {course?.name}
      </div>
      <div className="text-center w-24 mx-auto border-b-2 mt-4 border-green-admin-light"></div>
      {course &&
        <>
          <div className="grid grid-cols-1 gap-2 md:grid-cols-2 mt-5">
            <div>
              <img className="w-full hidden md:block" src={getImage(course.position)} alt="Imagen curso academia" />
            </div>
            <div className="max-w-md lg:max-w-sm mx-auto rounded overflow-hidden bg-white border border-gray-300 shadow-lg">
              <img className="w-full block md:hidden" src={getImage(course.position)} alt="Imagen curso academia" />
              <div className="px-6 pt-4 pb-4">
                <div className="flex items-center gap-x-2 justify-start">
                  {[1, 2, 3, 4, 5].map(_ => (
                    <AiFillStar size={18} style={{ color: "#f9d203" }} />
                  ))}
                </div>
                <div className="font-normal text-base text-left h-14 flex items-center mb-2">    {course.position} {course.name} </div>
                <div className="gap-2 text-sm font-light py-1 rounded-sm text-gray-500 line-through">antes {course.priceBefore}</div>
                <div className="text-5xl text-green-admin-light  font-normal border-b border-gray-200">
                  {course.priceNow}
                </div>

                <div className="flex items-center justify-start mt-2 text-gray-500 gap-2 text-sm font-medium">
                  <span>ó</span>
                  <span>{course.priceNowCop}</span>
                </div>
                <div
                  onClick={() => visitWhatsapp()}
                  className="px-4 py-3 cursor-pointer rounded-md text-white font-normal w-fit mt-8 bg-green-admin-light">
                  Comprar ahora
                </div>
              </div>

            </div>
          </div>
          
          <div className="flex items-center gap-2 mt-4 text-lg ">
            <div
            onClick={() => setTab('description')}
            className={classNames(tab === 'description' ? 'bg-green-admin-light text-white' : 'bg-gray-300 text-black',"cursor-pointer font-medium px-4 py-2 rounded-md")}>Descripción</div>
            <div
            onClick={() => setTab('opinion')}
            className={classNames(tab === 'opinion' ? 'bg-green-admin-light text-white' : 'bg-gray-300 text-black',"cursor-pointer font-medium px-4 py-2 rounded-md")}>Opiniones ({course.opinions?.length}) </div>
          </div>
          {course && tab === 'description' ?
            <div className="mt-1 bg-white shadow-md py-3 px-2 border border-gray-300 rounded-sm px-1 lg:px-6">
              <div className="font-semibold" dangerouslySetInnerHTML={{__html: course.description}}>

              </div>
              <div className="flex flex-wrap items-center gap-1 mt-3">
                {course.lesson.map(l => (
                  <>
                  <div className="w-full">
                    <div className="rounded-full py-1 text-lg font-semibold text-black w-full">
                      {l.name}
                    </div>
                    {l.text}
                  </div>
                  <div className="text-center w-24  border-b-2 mt-4 border-green-admin-light"></div>
                  </>
                ))}
              </div>
            </div>
          :
          <div className="mt-1 bg-white shadow-md py-3 px-2 border border-gray-300 rounded-sm">
          <div className="flex flex-wrap items-center gap-1 mt-3">
            {course?.opinions?.map(l => (
              <>
              <div className="w-full px-4 py-1 rounded-md border border-gray-300 mb-2">
                <div className="rounded-full py-1 text-lg font-semibold text-black w-full flex items-center gap-2">
                  <FaUserCircle size={24} className="text-green-admin-light" />
                  {l.name}
                </div>
                {l.text}
              </div>
              </>
            ))}
          </div>
        </div>
          }
        </>
      }
    </section>
  )
}
export default AcademyDetail 