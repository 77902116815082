import { Dialog, Transition } from "@headlessui/react";
import { Menu } from "antd";
import { Dropdown, Space } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CgSpinner } from "react-icons/cg";
import APIService from "../../services/Api";
import ViewDepositStatus from "./ViewDepositStatus";
type depositStatusProps = {
  currentStatus: string;
  depositId: string | number;
  userId: string | number
  depositCode: string 
};
const DepositStatus = ({ depositId, depositCode, currentStatus, userId }: depositStatusProps) => {
  const { t } = useTranslation();
  const [showStatus, setShowStatus] = useState(currentStatus);
  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [newStatus, setNewStatus] = useState<string>("");
  const [motive, setMotive] = useState<string>("");
  const [checkConfirmate, setCheckConfirmate] = useState<boolean>(false);
  const openConfirmation = (setStatus: string) => {
    setShowConfirmation(true);
    setNewStatus(setStatus);
  };

  useEffect(() => {
    setShowStatus(currentStatus)
  }, [currentStatus])
  
  const translateStatus = (v:string) => {
    switch(v){
      case  'waiting': 
      return 'esperando pago'
      case  'moneyreceived': 
      return 'dinero recibido'      
      case 'completed':
        return 'completada'
      case 'refused':
        return 'rechazada'
      case 'process':
          return 'en proceso'
      case 'checking':
          return 'en verificación'
      default: 
        return '---'
    }
  }
  const sendNotification = async () => {
    const data = {
      message: `Tu transferencia #${depositCode} ha sido actualizada a estado ${translateStatus(newStatus)}`,
      userId: userId,
      type:'deposit',
      typeId: depositId,
      link: `/transferencia/${depositId}`
    }
    await APIService.notificationCreate(data)
  }
  const handleUpdated = async () => {
    setLoading(true);
    const data = {
      deposit: depositId,
      newstatus: newStatus,
      origen: 'admin'
    } as any;
    if (newStatus === "cancel" || newStatus === "refused") {
      data.motive = motive;
    }
    const updated = await APIService.adminDepositUpdateStatus(data);
    if (updated.data.status) {
      sendNotification()
      setShowStatus(newStatus);
      setLoading(false);
      setCheckConfirmate(false);
      setShowConfirmation(false);
    } else {
      setLoading(false);
      setShowConfirmation(false);
    }
  };

  const itemListed = [];
  let menu = (
    <Menu
      items={[
        {
          key: "2",
          label: (
            <div onClick=
            { 
              ["process","checking","cancel","refused","completed"].includes(showStatus) ? undefined :() => openConfirmation("checking")
            }>
              {t("depositstatus.checking")}
            </div>
          ),
          disabled: [
            "process",
            "checking",
            "cancel",
            "refused",
            "completed",
          ].includes(showStatus)
            ? true
            : false,
        },
        {
          key: "3",
          label: (
            <div onClick=
            { 
              ["process","checking","cancel","refused","completed"].includes(showStatus) ? undefined :() => openConfirmation("moneyreceived")
            }>
              {t("depositstatus.moneyreceived")}
            </div>
          ),
          disabled: [
            "process",
            "checking",
            "cancel",
            "refused",
            "completed",
          ].includes(showStatus)
            ? true
            : false,
        },        
        /*{
          key: "3",
          label: (
            <div onClick={ ["cancel", "refused", "completed"].includes(showStatus) ? undefined : () => openConfirmation("cancel")}>
              {t("depositstatus.cancel")}
            </div>
          ),
          disabled: ["cancel", "refused", "completed"].includes(showStatus)
            ? true
            : false,
        },*/
        {
          key: "4",
          label: (
            <div onClick={ ["cancel", "refused", "completed"].includes(showStatus) ? undefined : () => openConfirmation("refused")}>
              {t("depositstatus.refused")}
            </div>
          ),
          disabled: ["cancel", "refused", "completed"].includes(showStatus)
            ? true
            : false,
        },
        {
          key: "5",
          label: (
            <div onClick={ ["cancel", "refused", "completed"].includes(showStatus) ? undefined : () => openConfirmation("completed")}>
              {t("depositstatus.completed")}
            </div>
          ),
          disabled: ["cancel", "refused", "completed"].includes(showStatus)
            ? true
            : false,
        },
      ]}
    />
  );

  const handleMotive = (motive: string) => {
    setMotive(motive);
  };

  const checkShowButton = () => {
    let response = true
    if(!checkConfirmate){
      response = false
    }
    if(['cancel','refused'].includes(newStatus) && !motive ){
      response = false
    }
    return response
  }
  return (
    <div>
      <Dropdown overlay={menu}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            {!loading ? (
              <ViewDepositStatus currentStatus={showStatus} />
            ) : (
              <div className="flex items-center justify-center w-full">
                <CgSpinner className="text-dark-green animate-spin" size={27} />
              </div>
            )}
          </Space>
        </a>
      </Dropdown>
      <Transition appear show={showConfirmation} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShowConfirmation(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {t("confirmation")}
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="text-sm flex items-center">
                      {t("aresurestatus")}{" "}
                      <div className="ml-2">
                        {" "}
                        <ViewDepositStatus currentStatus={newStatus} />{" "}
                      </div>
                    </div>
                    <div className="mt-2">¡{t("aresurestatus2")}!</div>
                  </div>
                  <div className="mt-2 font-semibold">
                    {t("aresurestatus3")} <br/># {depositCode}
                  </div>
                  <div className="mt-2 flex items-center bg-gray-200 border border-gray-400 px-1 py-1 rounded-lg">
                    <input
                      type="checkbox"
                      checked={checkConfirmate}
                      id={`confirmate${depositId}`}
                      onClick={() => setCheckConfirmate(!checkConfirmate)}
                    />
                    <label htmlFor={`confirmate${depositId}`} className="ml-1">
                      {t("checkconfirmstatus")}
                    </label>
                  </div>
                  {checkConfirmate &&
                    (newStatus === "cancel" || newStatus === "refused") && (
                      <div>
                        {t("motivebystatus")}
                        <textarea
                          onChange={(e) => handleMotive(e.target.value)}
                          className="bg-gray-100 border w-11/12 border-gray-200 rounded-lg px-2 py-2"
                        ></textarea>
                      </div>
                    )}
                  <div className="mt-4">
                    {checkShowButton() && (
                      <button
                        type="button"
                        className="inline-flex mr-2 justify-center rounded-md border bg-dark-green text-white px-4 py-2 text-sm font-medium"
                        onClick={() => handleUpdated()}
                      >
                        {t("confirm")}
                      </button>
                    )}
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border  border-dark-green px-4 py-2 text-sm font-medium text-black"
                      onClick={() => setShowConfirmation(false)}
                    >
                      {t("cancel")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default DepositStatus;
