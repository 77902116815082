import { Link } from "react-router-dom";

const TournamentInvitation: React.FC = () => {
  return (
    <div>
      <div className="bg-tournament text-white text-shadow flex flex-col items-start px-10 justify-center">
        <div className="bg-black-tournament font-bold py-4 px-8 rounded-md text-center w-11/12 md:w-10/12 mx-auto">
          <div className="text-4xl text-shadow-general">
            ¡Invitación al Gran Torneo de Trading!
          </div>
          <div className="mt-4 text-left font-normal text-base text-shadow-general">
            Estimados traders, Nos complace anunciar el Gran Torneo de Trading,
            una emocionante oportunidad para poner a prueba tus habilidades y
            competir con los mejores en el mundo del trading.
          </div>
          <div className="mt-4 text-left font-normal text-base text-shadow-general">
            Únete a nosotros para una jornada llena de adrenalina, aprendizaje y
            la posibilidad de ganar increíbles premios. Este torneo está abierto
            a traders de todos los niveles, desde principiantes hasta expertos.
            No importa si operas en Forex, acciones o criptomonedas, ¡todos son
            bienvenidos!
          </div>
          <Link 
            to="https://torneosinfronteras.com/" 
            target="_blank"
            className="bg-red-700 text-white w-fit mx-auto text-2xl font-bold py-2 px-2 mt-10"
          >
            Unirse
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TournamentInvitation;
