import { Link } from "react-router-dom";

const DerivRechange:React.FC = () => {
  return (
    <div>
      <div className="text-center bg-derivnequi mb-14  text-base md:text-2xl bg-rose-300 py-7 font-normal">
      Ahora tus recargas a Deriv son instantáneas 
      <div>
        Funciona 24/7 , sigue los pasos y paga desde app Nequi.
      </div>
      <div className="mt-10">
        <Link to="https://t.me/tepagoya_bot" className="bg-white text-black hover:text-black rounded-md px-2 py-2 ">
          Recargar 
        </Link>
      </div>

      </div>  
    </div>
  );
};

export default DerivRechange;
