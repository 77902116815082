import { useEffect, useState } from "react"
import logo from "../../assets/images/logoisf.png"
import logoIsfNav from "../../assets/images/logoisfnav.png"
import Language from "../Language"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import { AiFillHome } from "react-icons/ai"
import { FaUserCircle, FaUserPlus } from "react-icons/fa"
import { UseAuth } from "src/auth/AuthContext"
import APIService from "src/services/Api"
import { GrInfo } from "react-icons/gr"
import { IoMdClose } from "react-icons/io"
//import { FcMindMap } from "react-icons/fc"
import { ImMenu } from "react-icons/im"
import { MdDashboard } from "react-icons/md"
import { TbCoffee } from "react-icons/tb";
import { FcMindMap } from "react-icons/fc";
import { BiVideo } from "react-icons/bi";

const Navbar = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = UseAuth()
  const [showMovil, setShowMovil] = useState<boolean>(false)

  useEffect(() => { APIService.getToken() }, [])

  return (
    <div>
      <nav
        className="bg-white flex justify-between items-center  border-2 top-0 border-light-grey px-2 sm:px-4 py-1 w-full shadow-2xl fixed"
        style={{ zIndex: "1000" }}
      >
        <a href="/inicio" className="flex items-center py-2">
          <img alt="icon" className="w-32 h-auto" src={logoIsfNav} />
        </a>

        <div
          className="container flex flex-wrap justify-between items-center mx-auto"
        >
          <div className="w-40 h-1"></div>
          {!showMovil ?
            <button
              data-collapse-toggle="mobile-menu"
              onClick={() => setShowMovil(true)}
              type="button"
              className="inline-flex items-center p-2 ml-3 text-sm text-white rounded-lg xl:hidden bg-green-admin-light focus:outline-none"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <ImMenu className="text-white" size={24} />
            </button>
            :
            <button
              data-collapse-toggle="mobile-menu"
              onClick={() => setShowMovil(false)}
              type="button"
              className="inline-flex items-center p-2 ml-3 text-sm text-white rounded-lg xl:hidden bg-green-admin-light focus:outline-none"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <IoMdClose className="text-white" size={27} />
            </button>
          }
          <div className="hidden w-full xl:block xl:w-auto" id="mobile-menu">
            <ul
              className="flex flex-col items-center md:flex-row md:space-x-3 md:mt-0 md:text-sm md:font-normal"
            >
              {isAuthenticated && (
                <li>
                  <Link to="/inicio" >
                    <button
                      className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                    >
                      <MdDashboard  className="text-green-admin-light" size={23} />
                      {t('dashboard')}
                    </button>
                  </Link>
                </li>
              )}
              <li>
                <Link to="https://torneosinfronteras.com?origen=isf"  target="_blank">
                  <button
                    className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                  >
                    {t('tournament')}
                  </button>
                </Link>
              </li>               
 {/*              <li>
                <Link to="/videos" >
                  <button
                    className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                  >
                    Videos
                  </button>
                </Link>
              </li>  */}             
              <li>
                <Link to="/quienes-somos" >
                  <button
                    className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                  >
                    {t('aboutus')}
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/academia" >
                  <button
                    className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                  >
                    {t('academytext')}
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/blog" >
                  <button
                    className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                  >
                    {t('blogtext')}
                  </button>
                </Link>
              </li>
              {!isAuthenticated && (
                <>
                  <li>
                    <Link to="/login" >
                      <button
                        className="font-medium border border-green-admin-light text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                      >
                        {t('login')}
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/register" >
                      <button
                        className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                      >
                        {t('register')}
                      </button>
                    </Link>
                  </li>
                </>
              )}
              <li>
                <Language />
              </li>
            </ul>
          </div>
        </div >
      </nav >
      {showMovil &&
        <div className="movil-nav">
          <div className="flex justify-between items-center w-full border-b shadow-2xl mb-4">
            {<img alt="icon" style={{ width: "16rem" }} src={logo} />}
            <div style={{width:"16rem"}}></div>
            <i
              onClick={() => setShowMovil(false)}
              className="fa-solid fa-circle-xmark text-green-logo mr-4 mt-2 text-3xl"
            ></i>
          </div>

          <ul
            className="flex flex-col items-center mt-14   "
          >


            {isAuthenticated ? (
              <li>
                <Link to="/inicio" >
                  <button
                    className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                  >
                    {t('dashboard')}
                  </button>
                </Link>
              </li>
            )
              : (
                <li>
                  <Link to="/" >
                    <button
                      className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                    >
                      {t('home')}
                    </button>
                  </Link>
                </li>
              )}

            {!isAuthenticated && (
              <>
                <li>
                  <Link to="/login" >
                    <button
                      className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                    >
                      {t('login')}
                    </button>
                  </Link>
                </li>
                <li>
                  <Link to="/register" >
                    <button
                      className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                    >
                      {t('register')}
                    </button>
                  </Link>
                </li>
              </>
            )}


            <li>
              <Link to="/quienes-somos" >
                <button
                  className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                >
                  {t('aboutus')}
                </button>
              </Link>
            </li>
            <li>
              <Link to="/academia" >
                <button
                  className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                >
                  {t('academytext')}
                </button>
              </Link>
            </li>
            <li>
                <Link to="https://torneosinfronteras.com?origen=isf"  target="_blank">
                  <button
                    className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                  >
                    {t('tournament')}
                  </button>
                </Link>
              </li>               
            {/*   <li>
                <Link to="/videos" >
                  <button
                    className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                  >
                    Videos
                  </button>
                </Link>
              </li>    */}             
            <li>
              <Link to="/blog" >
                <button
                  className="font-medium text-black rounded-md flex flex-wrap items-center gap-2 text-base cursor-pointer transition duration-200 ease-in-out transform  hover:bg-green-admin-light hover:text-white px-2 py-2"
                >
                  {t('blogtext')}
                </button>
              </Link>
            </li>

            <li>
              <Language />
            </li>


          </ul >
        </div >

      }
      <div className="mb-10 pb-10"></div>
    </div >
  )
}

export default Navbar 